import React, { useState, useEffect } from 'react';
import { Box, Typography, Rating, TextField, Button, IconButton, CircularProgress } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useStytchUser } from '@stytch/react';
import AuthModal from '../auth-modal/AuthModal';
import { Review } from '../../types/Review';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmation from '../delete-confirmation/DeleteConfirmation';
import { User } from '../../types/User';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImagePreviewModal from '../image-preview/ImagePreviewModal';
import VerifyEmailModal from '../verify-email-modal/VerifyEmailModal';
import { useReviews } from '../../hooks/useReviews';

interface ReviewSectionProps {
    meetId: string;
    user?: User | null;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ meetId, user }) => {
    const [rating, setRating] = useState<number | null>(null);
    const [comment, setComment] = useState('');
    const [images, setImages] = useState<File[]>([]);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
    const { user: stytchUser } = useStytchUser();
    const { reviews, createReview, deleteReview } = useReviews(meetId);
    const MAX_CHARS = 1000;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [reviewToDelete, setReviewToDelete] = useState<string | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedReviewImages, setSelectedReviewImages] = useState<string[]>([]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newImages = Array.from(event.target.files);
            setImages(prev => [...prev, ...newImages].slice(0, 5));
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!rating) return;

        setIsSubmitting(true);
        try {
            const newReview = await createReview({ meetId, rating, comment, images });
            setRating(null);
            setComment('');
            setImages([]);

            // Update the user object with the new review ID
            if (user && newReview.id) {
                user.reviews = [...user.reviews, newReview.id];
            }

        } catch (error) {
            console.error('Error submitting review:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const isUserReview = (reviewId: string): boolean => {
        return user?.reviews.includes(reviewId) || false;
    };

    const userLeftReview = reviews.some(review => isUserReview(review.id));

    const sortedReviews = [...reviews].sort((a, b) => {
        // Put user's review first
        if (isUserReview(a.id)) return -1;
        if (isUserReview(b.id)) return 1;
        return 0;
    });

    const handleDeleteClick = (reviewId: string) => {
        setReviewToDelete(reviewId);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirm = async () => {
        if (reviewToDelete) {
            setIsDeleting(true);
            try {
                await deleteReview(reviewToDelete);
            } catch (error) {
                console.error('Error deleting review:', error);
            } finally {
                setIsDeleting(false);
                setShowDeleteConfirmation(false);
                setReviewToDelete(null);
            }
        }
    };

    const handleImageDelete = (indexToDelete: number) => {
        setImages(prev => prev.filter((_, index) => index !== indexToDelete));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const checkScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1); // -1 for rounding errors
        }
    };

    useEffect(() => {
        checkScrollButtons();
    }, [images]); // Check when images change

    const handleScroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            const scrollAmount = 110; // image width + gap
            const newScrollPosition = scrollContainerRef.current.scrollLeft +
                (direction === 'left' ? -scrollAmount : scrollAmount);
            scrollContainerRef.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleImageClick = (images: string[], index: number) => {
        setSelectedImageIndex(index);
        setSelectedReviewImages(images);
        setShowImagePreview(true);
    };

    const handleCloseImagePreview = () => {
        setShowImagePreview(false);
        setSelectedReviewImages([]);
        setSelectedImageIndex(0);
    };

    const handleNotAuthorizedClick = () => {
        if (!stytchUser) {
            setShowAuthModal(true);
        } else if (!user?.isVerified) {
            setShowVerifyEmailModal(true);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" >Reviews</Typography>
            {!userLeftReview && (
                <>
                    {(stytchUser && user?.isVerified) ? (
                        <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
                            <Rating
                                value={rating}
                                onChange={(_, newValue) => setRating(newValue)}
                                size="large"
                                icon={<Typography sx={{ fontSize: '18pt', pr: 0.6 }}>⭐️</Typography>}
                                emptyIcon={<Typography sx={{ fontSize: '18pt', pr: 0.6 }}>⭐️</Typography>}
                            />
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                value={comment}
                                onChange={(e) => {
                                    if (e.target.value.length <= MAX_CHARS) {
                                        setComment(e.target.value);
                                    }
                                }}
                                placeholder="Schrijf je review..."
                                sx={{ mt: 2 }}
                                inputProps={{
                                    maxLength: MAX_CHARS
                                }}
                                helperText={`${comment.length}/${MAX_CHARS} karakters`}
                                FormHelperTextProps={{
                                    sx: {
                                        textAlign: 'right',
                                        mr: 0
                                    }
                                }}
                            />
                            <Box sx={{ position: 'relative', mt: 2 }}>
                                {images.length > 0 && (
                                    <>
                                        {canScrollLeft && (
                                            <IconButton
                                                onClick={() => handleScroll('left')}
                                                sx={{
                                                    position: 'absolute',
                                                    left: -20,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 2,
                                                    backgroundColor: 'background.paper',
                                                    '&:hover': { backgroundColor: 'action.hover' },
                                                    boxShadow: 2,
                                                }}
                                            >
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        )}
                                        {canScrollRight && (
                                            <IconButton
                                                onClick={() => handleScroll('right')}
                                                sx={{
                                                    position: 'absolute',
                                                    right: -20,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 2,
                                                    backgroundColor: 'background.paper',
                                                    '&:hover': { backgroundColor: 'action.hover' },
                                                    boxShadow: 2,
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                                <Box
                                    ref={scrollContainerRef}
                                    onScroll={checkScrollButtons}
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        overflowX: 'auto',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        },
                                        msOverflowStyle: 'none',
                                        px: 1,
                                    }}
                                >
                                    {images.map((image, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                position: 'relative',
                                                minWidth: 100, // Ensure fixed width
                                                width: 100,
                                                height: 100,
                                                borderRadius: 1,
                                                overflow: 'hidden',
                                                flexShrink: 0, // Prevent images from shrinking
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={URL.createObjectURL(image)}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => handleImageDelete(index)}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 4,
                                                    right: 4,
                                                    padding: '4px',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                                    }
                                                }}
                                            >
                                                <DeleteIcon sx={{ fontSize: 16, color: 'white' }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box sx={{ mt: 2, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-start' }}>
                                {images.length < 5 && (
                                    <Button variant="outlined" color="secondary" startIcon={<PhotoCamera />} sx={{ height: '42px' }} component="label">
                                        <Typography>Foto&apos;s</Typography>
                                        <input
                                            ref={fileInputRef}
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            multiple
                                            onChange={handleImageUpload}
                                        />
                                    </Button>
                                )}
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        ml: 'auto',
                                        animation: images.length === 5 ? 'pulse 2s infinite' : 'none',
                                        '@keyframes pulse': {
                                            '0%': { opacity: 1 },
                                            '50%': { opacity: 0.6 },
                                            '100%': { opacity: 1 },
                                        }
                                    }}
                                >
                                    {images.length}/5 foto&apos;s
                                </Typography>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!rating || isSubmitting}
                                    sx={{ position: 'relative', minWidth: '140px', height: '42px' }}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px'
                                            }}
                                        />
                                    ) : (
                                        'Plaats Review'
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    ) : (!stytchUser || !user?.isVerified) && (
                        <Button
                            variant="contained"
                            onClick={handleNotAuthorizedClick}
                            sx={{ mt: 4 }}
                        >
                            Review deze meet
                        </Button>
                    )}
                </>
            )}

            <Box sx={{ mt: 4 }}>
                {userLeftReview && (
                    <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>Jouw review</Typography>
                )}
                {sortedReviews.map((review: Review) => (
                    <Box
                        key={review.id}
                        sx={{
                            mb: 3,
                            p: 2,
                            bgcolor: 'primary.main',
                            border: isUserReview(review.id) ? '1px solid white' : 'none',
                            borderRadius: 1,
                            position: 'relative'
                        }}
                    >
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Rating
                                value={review.rating}
                                icon={<Typography sx={{ fontSize: '16pt', pr: 0.3 }}>⭐️</Typography>}
                                emptyIcon={<Typography sx={{ fontSize: '16pt', pr: 0.3 }}>⭐️</Typography>}
                                readOnly />
                            <Typography variant="body2" sx={{ mr: userLeftReview ? 4 : 0 }}>
                                {new Date(review.createdAt).toLocaleDateString()}
                            </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ mt: 1 }}>{review.comment}</Typography>
                        {review.images && (
                            <Box sx={{ position: 'relative', mt: 2 }}>
                                {review.images.length > 1 && (
                                    <>
                                        {canScrollLeft && (
                                            <IconButton
                                                onClick={() => handleScroll('left')}
                                                sx={{
                                                    position: 'absolute',
                                                    left: -20,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 2,
                                                    backgroundColor: 'background.paper',
                                                    '&:hover': { backgroundColor: 'action.hover' },
                                                    boxShadow: 2,
                                                }}
                                            >
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        )}
                                        {canScrollRight && (
                                            <IconButton
                                                onClick={() => handleScroll('right')}
                                                sx={{
                                                    position: 'absolute',
                                                    right: -20,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 2,
                                                    backgroundColor: 'background.paper',
                                                    '&:hover': { backgroundColor: 'action.hover' },
                                                    boxShadow: 2,
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                                <Box
                                    ref={scrollContainerRef}
                                    onScroll={checkScrollButtons}
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        overflowX: 'auto',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        },
                                        msOverflowStyle: 'none',
                                        px: 1,
                                    }}
                                >
                                    {review.images.map((image: string, index: number) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                position: 'relative',
                                                minWidth: 100,
                                                width: 100,
                                                height: 100,
                                                borderRadius: 1,
                                                overflow: 'hidden',
                                                flexShrink: 0,
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={`${process.env.REACT_APP_API_BASE_URL}${image}`}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                    transition: 'transform 0.2s',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)'
                                                    }
                                                }}
                                                onClick={() => handleImageClick(review.images, index)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {(isUserReview(review.id) || user?.isAdmin) && (
                            <IconButton
                                onClick={() => handleDeleteClick(review.id)}
                                sx={{
                                    position: 'absolute',
                                    top: 12,
                                    right: 12,
                                    height: 24,
                                    width: 24
                                }}
                            >
                                {showDeleteConfirmation ? (
                                    <Typography
                                        sx={{
                                            fontSize: '16pt',
                                            animation: 'bounceDelete 0.3s',
                                            '@keyframes bounceDelete': {
                                                '0%': { transform: 'perspective(400px) scale(0)' },
                                                '50%': { transform: 'perspective(400px) scale(1.5)' },
                                                '100%': { transform: 'perspective(400px) scale(1)' },
                                            }
                                        }}
                                    >
                                        🗑️
                                    </Typography>
                                ) : (
                                    <DeleteIcon />
                                )}
                            </IconButton>
                        )}
                    </Box>
                ))}
            </Box>

            <AuthModal
                open={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                message="Je moet ingelogd zijn om een review achter te kunnen laten"
            />
            {user?.email && !user?.isVerified && (
                <VerifyEmailModal
                    open={showVerifyEmailModal}
                    onClose={() => setShowVerifyEmailModal(false)}
                    email={user?.email}
                />
            )}
            <DeleteConfirmation
                open={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                onConfirm={handleDeleteConfirm}
                type="review"
                isLoading={isDeleting}
            />

            <ImagePreviewModal
                open={showImagePreview}
                onClose={handleCloseImagePreview}
                images={selectedReviewImages}
                initialIndex={selectedImageIndex}
            />
        </Box>
    );
};

export default ReviewSection; 