import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Snackbar, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStytch } from '@stytch/react';
import FullScreenLoader from '../loader/FullScreenLoader';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useUser } from '../../hooks/useUser';

type ProfileModalProps = {
    open: boolean;
    onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ open, onClose }) => {
    const stytch = useStytch();
    const { user } = useUser();

    const handleLogout = () => {
        stytch.session.revoke();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="profile-modal-title"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 320,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                pt: 1,
                borderRadius: 2,
                outline: 'none',
            }}>
                <Box sx={{ display: 'flex', width: '100%', mb: 3, justifyContent: 'space-between' }}>
                    <Typography id="profile-modal-title" variant="h6" component="h2" gutterBottom>
                        Profiel
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </Box>
                {!user ? <FullScreenLoader /> : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body1">{`Email: ${user.email}`}</Typography>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleLogout}
                            fullWidth
                        >
                            Uitloggen
                        </Button>
                        <Button
                            variant="text"
                            href="mailto:info@carmeeter.com"
                            startIcon={<MailOutlineIcon />}
                            sx={{
                                mt: 2,
                                width: '100%',
                                color: 'text.secondary',
                                borderRadius: 1,
                                padding: '8px 16px',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        >
                            Contact
                        </Button>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default ProfileModal;