import { useMap } from 'react-leaflet';
import { useEffect, useRef } from 'react';

type MapCenterHandlerProps = {
    center: [number, number];
    zoom: number;
};

const MapCenterHandler: React.FC<MapCenterHandlerProps> = ({ center, zoom }) => {
    const map = useMap();
    const initialZoom = useRef(map.getZoom());

    useEffect(() => {
        if (map.getCenter().lat !== center[0] || map.getCenter().lng !== center[1]) {
            // Only use the provided zoom on initial load
            const currentZoom = initialZoom.current === map.getZoom() ? zoom : map.getZoom();
            map.setView(center, currentZoom, { animate: true });
        }
    }, [center, zoom]);

    return null;
};

export default MapCenterHandler; 