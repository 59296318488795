import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#111418',
            padding: 3
        }}>
            <Box sx={{
                backgroundColor: 'background.paper',
                padding: 3,
                borderRadius: 2,
                maxWidth: 400,
                width: '100%',
                textAlign: 'center'
            }}>
                <Typography variant="h5" gutterBottom>
                    Pagina niet gevonden
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    De pagina waar je naar zoekt bestaat niet.
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate('/', { replace: true })}
                >
                    Terug naar overzicht
                </Button>
            </Box>
        </Box>
    );
};

export default NotFound; 