import React from 'react';
import { Box } from '@mui/material';
import EventForm from '../components/event-form/EventForm';
import { useNavigate, useParams } from 'react-router-dom';
import TopBar from '../components/top-bar/TopBar';

const CreateMeet = () => {
    const navigate = useNavigate();
    const { meetId } = useParams<{ meetId: string }>();

    return (
        <Box>
            <TopBar onBack={() => navigate('/')} variant='details' title={meetId ? 'Bewerk meet' : 'Nieuwe meet'} />
            <EventForm meetId={meetId} />
        </Box>
    );
};

export default CreateMeet;