import { HostTier } from '../types/User';

export const isValidHostTier = (tier: string): tier is HostTier => {
    return ['free', 'gold', 'diamond'].includes(tier);
};

export const canAccessFeature = (userTier: HostTier | null, requiredTier: HostTier): boolean => {
    if (!userTier) return false;
    
    const tiers: HostTier[] = ['free', 'gold', 'diamond'];
    const userTierIndex = tiers.indexOf(userTier);
    const requiredTierIndex = tiers.indexOf(requiredTier);
    
    return userTierIndex >= requiredTierIndex;
}; 