import React, { useState, useEffect } from 'react';
import { Fab, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import AuthModal from '../auth-modal/AuthModal';
import { useStytchUser } from '@stytch/react';

interface CreateMeetButtonProps {
    isHost: boolean;
}

const CreateMeetButton: React.FC<CreateMeetButtonProps> = ({ isHost }) => {
    const navigate = useNavigate();
    const [showRegisterHostModal, setShowRegisterHostModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const { user: stytchUser } = useStytchUser();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsExpanded(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    const handleClick = () => {
        if (!stytchUser) {
            setShowLoginModal(true);
        } else if (!isHost) {
            setShowRegisterHostModal(true);
        } else {
            navigate('/create');
        }
    };

    const handleHostRegistration = () => {
        navigate('/register-host');
        setShowRegisterHostModal(false);
    };

    return (
        <>
            <Fab
                color="primary"
                aria-label="add"
                onClick={handleClick}
                onTouchStart={() => setIsExpanded(true)}
                onTouchEnd={() => setIsExpanded(false)}
                onMouseEnter={() => setIsExpanded(true)}
                onMouseLeave={() => setIsExpanded(false)}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    zIndex: 999,
                    minWidth: isExpanded ? '180px' : '56px',
                    borderRadius: isExpanded ? '28px' : '50%',
                    transition: 'all 0.3s ease-in-out',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: isExpanded ? '0 20px' : 0,
                    '& .MuiSvgIcon-root': {
                        transition: 'margin 0.3s ease-in-out',
                        marginRight: isExpanded ? 1 : 0,
                    },
                }}
            >
                <AddIcon />
                <Typography
                    sx={{
                        opacity: isExpanded ? 1 : 0,
                        maxWidth: isExpanded ? '120px' : '0',
                        overflow: 'hidden',
                        transition: 'all 0.3s ease-in-out',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Nieuwe Meet
                </Typography>
            </Fab>

            <AuthModal
                open={showLoginModal}
                onClose={() => setShowLoginModal(false)}
                message="Log in om een meet aan te maken"
                customButton={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleHostRegistration}
                        fullWidth
                    >
                        Log in
                    </Button>
                }
            />
            <AuthModal
                open={showRegisterHostModal}
                onClose={() => setShowRegisterHostModal(false)}
                message="Je bent nog geen host"
                customButton={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleHostRegistration}
                        fullWidth
                    >
                        Registreer als host
                    </Button>
                }
            />
        </>
    );
};

export default CreateMeetButton;
