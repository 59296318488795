import useSWR from 'swr';
import { useStytch } from '@stytch/react';
import { Meet as Meet, MeetDTO as MeetDTO } from '../types/Meet';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';

export const useMeets = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: meets, error, isLoading, mutate } = useSWR<Meet[]>(
        [`${process.env.REACT_APP_API_BASE_URL}/meets`, sessionToken],
        fetcher,
        defaultConfig
    );

    const createMeet = async (meetData: MeetDTO) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(meetData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const newMeet = await response.json();

            return newMeet;
        } catch (error) {
            console.error('Error creating meet:', error);
            throw error;
        }
    };

    const updateMeet = async (id: string, meetData: Partial<MeetDTO>) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(meetData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedMeet = await response.json();
            mutate(meets?.map(meet =>
                meet.id === id ? updatedMeet : meet
            ));
            return updatedMeet;
        } catch (error) {
            console.error('Error updating meet:', error);
            throw error;
        }
    };

    const deleteMeet = async (id: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            mutate(meets?.filter(meet => meet.id !== id));
        } catch (error) {
            console.error('Error deleting meet:', error);
            throw error;
        }
    };

    const attendMeet = async (meetId: string, eventDate: Date) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/attend/${meetId}`,
                { arg: { attendedAt: eventDate } },
                sessionToken
            );
            mutate();
        } catch (error) {
            console.error('Error attending meet:', error);
            throw error;
        }
    };

    const unattendMeet = async (meetId: string, eventDate: Date) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/unattend/${meetId}`,
                { arg: { eventDate } },
                sessionToken
            );
            mutate();
        } catch (error) {
            console.error('Error unattending meet:', error);
            throw error;
        }
    };

    const generateSeoDescriptionPreview = async (meetData: Partial<Meet>) => {
        return mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/description/preview`,
            { arg: { meetData } },
            sessionToken
        );
    };

    const generateSeoKeywordsPreview = async (meetData: Partial<Meet>) => {
        return mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords/preview`,
            { arg: { meetData } },
            sessionToken
        );
    };

    const regenerateSeoDescription = async (id: string) => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/description`,
            {
                arg: {
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                }
            },
            sessionToken
        );
        return response.updatedMeets?.[0]?.seoDescription || null;
    };

    const regenerateSeoKeywords = async (id: string) => {
        const response = await mutationFetcher(
            `${process.env.REACT_APP_API_BASE_URL}/meets/seo/keywords`,
            {
                arg: {
                    meetIds: [id],
                    updateAll: false,
                    isConcept: true,
                }
            },
            sessionToken
        );
        return response.updatedMeets?.[0]?.seoKeywords || null;
    };

    return {
        meets: meets || [],
        error,
        isLoading,
        isError: !!error,
        mutate,
        createMeet,
        updateMeet,
        deleteMeet,
        attendMeet,
        unattendMeet,
        generateSeoDescriptionPreview,
        generateSeoKeywordsPreview,
        regenerateSeoDescription,
        regenerateSeoKeywords
    };
};

export const useMeet = (id: string | undefined) => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: meet, error, isLoading, mutate } = useSWR<Meet>(
        id && sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const updateMeet = async (meetData: Partial<MeetDTO>) => {
        if (!id) return;
        console.log('meetData', meetData);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meets/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`,
                },
                body: JSON.stringify(meetData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedMeet = await response.json();
            mutate(updatedMeet);
            return updatedMeet;
        } catch (error) {
            console.error('Error updating meet:', error);
            throw error;
        }
    };

    return {
        meet,
        error,
        isLoading,
        isError: !!error,
        mutate,
        updateMeet
    };
};
