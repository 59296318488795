import { useMap } from 'react-leaflet';
import { useEffect, useRef, useState } from 'react';

type MapSizeHandlerProps = {
    isExpanded: boolean;
};

const MapSizeHandler: React.FC<MapSizeHandlerProps> = ({ isExpanded }) => {
    const map = useMap();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 400);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 400);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Clear any existing timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Initial size update without changing view
        map.invalidateSize({ animate: false });

        // Update halfway through the transition
        timeoutRef.current = setTimeout(() => {
            map.invalidateSize({ animate: false });
        }, 150);

        // Final update after transition
        timeoutRef.current = setTimeout(() => {
            map.invalidateSize({ animate: false });
        }, 300);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isExpanded, map]);

    return null;
};

export default MapSizeHandler;
