import useSWR, { mutate } from 'swr';
import { useStytch } from '@stytch/react';
import { User } from '../types/User';
import { defaultConfig, fetcher, mutationFetcher } from '../lib/swr';

interface CreateUserParams {
    contestParticipant?: boolean;
    instagramHandle?: string;
    role?: 'host' | 'attendee';
    hostTier?: 'free' | 'gold' | 'diamond';
    hostName?: string;
}

export const useUser = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: user, error, isLoading } = useSWR<User>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/me`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const createUser = async (email: string, sessionToken: string, params?: CreateUserParams): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                },
                body: JSON.stringify({
                    email,
                    hasPassword: true,
                    contestParticipant: params?.contestParticipant,
                    instagramHandle: params?.instagramHandle,
                    role: params?.role,
                    hostTier: params?.hostTier,
                    hostName: params?.hostName
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    console.log('User already exists (409)');
                    return;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    };


    return {
        user,
        createUser,
        error,
        isLoading,
        isError: !!error
    };
};

export const useFavoriteMeets = () => {
    const stytch = useStytch();
    const sessionToken = stytch.session.getTokens()?.session_token;

    const { data: favorites, error, isLoading, mutate: mutateFavorites } = useSWR<string[]>(
        sessionToken ? [`${process.env.REACT_APP_API_BASE_URL}/users/favorites`, sessionToken] : null,
        fetcher,
        defaultConfig
    );

    const addFavorite = async (meetId: string) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/users/favorite`,
                { arg: { meetId } },
                sessionToken
            );
            mutateFavorites();
        } catch (error) {
            console.error('Error adding favorite:', error);
            throw error;
        }
    };

    const removeFavorite = async (meetId: string) => {
        try {
            await mutationFetcher(
                `${process.env.REACT_APP_API_BASE_URL}/users/unfavorite`,
                { arg: { meetId } },
                sessionToken
            );
            mutateFavorites();
        } catch (error) {
            console.error('Error removing favorite:', error);
            throw error;
        }
    };

    return {
        favorites: favorites || [],
        error,
        isLoading,
        isError: !!error,
        addFavorite,
        removeFavorite
    };
}; 