import React from 'react';
import { Card, Box, Skeleton } from '@mui/material';

const EventCardSkeleton = () => {
    return (
        <Card sx={{
            width: '100%',
            height: '290px',
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Skeleton
                variant="rectangular"
                height={125}
                sx={{ flexShrink: 0 }}
            />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                minWidth: '250px',
                width: '100%',
                position: 'relative'
            }}>
                <Box sx={{ p: '8px 16px 0 16px' }}>
                    <Skeleton variant="text" width="70%" height={24} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="50%" height={20} sx={{ mb: 1 }} />

                    <Box sx={{ mt: 1 }}>
                        <Box display="flex" alignItems="center" sx={{ mb: 0.5 }}>
                            <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
                            <Skeleton variant="text" width="60%" height={20} />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
                            <Skeleton variant="text" width="70%" height={20} />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: '8px 16px',
                    mt: 'auto'
                }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="circular" width={40} height={40} />
                    </Box>
                    <Skeleton variant="rectangular" width={140} height={38} sx={{ borderRadius: 1 }} />
                </Box>
            </Box>
        </Card>
    );
};

export default EventCardSkeleton; 