import { useStytch } from '@stytch/react';
import { User } from '../types/User';

type UserServiceType = {
    getFavoriteMeets: () => Promise<string[]>;
    addFavoriteMeet: (meetId: string) => Promise<void>;
    removeFavoriteMeet: (meetId: string) => Promise<void>;
    getMe: () => Promise<User | null>;
    generateReferralCode: () => Promise<string>;
    activateReferralCode: (code: string) => Promise<void>;
    createUser: (email: string, sessionToken: string, params?: CreateUserParams) => Promise<void>;
};

interface CreateUserParams {
    contestParticipant?: boolean;
    instagramHandle?: string;
    role?: 'host' | 'attendee';
    hostTier?: 'free' | 'gold' | 'diamond';
    hostName?: string;
}

const UserService = (): UserServiceType => {
    const stytch = useStytch();

    const getFavoriteMeets = async (): Promise<string[]> => {
        try {
            const sessionToken = stytch.session.getTokens()?.session_token;
            if (!sessionToken) {
                return [];
            }
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorites`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                },
            });
            if (!response.ok) {
                return [];
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem fetching favorite meets:', error);
            return [];
        }
    };

    const addFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem adding favorite meet:', error);
            throw error;
        }
    };

    const removeFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/unfavorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem removing favorite meet:', error);
            throw error;
        }
    };

    const getMe = async (): Promise<User | null> => {
        try {
            const sessionToken = stytch.session.getTokens()?.session_token;
            if (!sessionToken) {
                return null;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                },
            });

            if (response.status === 401 || response.status === 404) {
                return null;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('There was a problem fetching user:', error);
            return null;
        }
    };

    const generateReferralCode = async (): Promise<string> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/generate-referral`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data.code;
        } catch (error) {
            console.error('There was a problem generating referral code:', error);
            throw error;
        }
    };

    const activateReferralCode = async (code: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/activate-referral`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ code }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem activating referral code:', error);
            throw error;
        }
    };

    const createUser = async (email: string, sessionToken: string, params?: CreateUserParams): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                },
                body: JSON.stringify({
                    email,
                    hasPassword: true,
                    contestParticipant: params?.contestParticipant,
                    instagramHandle: params?.instagramHandle,
                    role: params?.role,
                    hostTier: params?.hostTier,
                    hostName: params?.hostName
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    console.log('User already exists (409)');
                    return;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error creating user:', error);
            throw error;
        }
    };

    return {
        getFavoriteMeets,
        addFavoriteMeet,
        removeFavoriteMeet,
        getMe,
        generateReferralCode,
        activateReferralCode,
        createUser
    };
};

export default UserService;
