import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Meet } from '../../types/Meet';
import FullScreenLoader from '../loader/FullScreenLoader';
import { User } from '../../types/User';
import { useStytchUser } from '@stytch/react';
import MeetCard from '../event-card/EventCard';
import PromotionalCard from '../promotional-card/PromotionalCard';
import sabrMotorsportLogo from '../../assets/sabrmotorsport.png';
import CompactPromotionalCard from '../promotional-card/CompactPromotionalCard';
import VerifyEmailCard from '../verify-email-card/VerifyEmailCard';
import EventCardSkeleton from '../event-card/EventCardSkeleton';

const ITEMS_PER_PAGE = 10;
const DISCOUNT_CODE = 'carmeeter';
const CONTEST_DISCOUNT_CODE = 'winactie100';

type EventListProps = {
    loading: boolean;
    eventCardsLoading: boolean;
    searchQuery: string;
    events: Meet[];
    selectedKeywords: string[];
    highlightedMeetId: string | null;
    favorites: string[];
    handleFavorite: (id: string) => Promise<void>;
    showFavorites: boolean;
    user: User | null;
    userLocation?: { lat: number, lng: number } | null;
    onScreenClick: () => void;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
}

const EventList = ({ searchQuery, events, loading, eventCardsLoading, selectedKeywords, user, highlightedMeetId, showFavorites, userLocation, onScreenClick, onHighlight, onEdit, favorites, handleFavorite }: EventListProps) => {
    const [filteredEvents, setFilteredEvents] = useState<Meet[]>([]);
    const [visibleEvents, setVisibleEvents] = useState<Meet[]>([]);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const { user: stytchUser } = useStytchUser();
    const isAdmin = useMemo(() => user?.isAdmin && stytchUser || false, [user, stytchUser]);

    const hasActiveFilters = useMemo(() => {
        return searchQuery.length > 0 || selectedKeywords.length > 0 || showFavorites;
    }, [searchQuery, selectedKeywords, showFavorites]);

    useEffect(() => {
        if (!loading) {
            const filtered = events.filter(event =>
                (event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    event.location.toLowerCase().includes(searchQuery.toLowerCase())) &&
                (selectedKeywords.length === 0 || selectedKeywords.every(kw => event.keywords.includes(kw)))
            );
            setFilteredEvents(filtered);
            setPage(1);
            setVisibleEvents(filtered.slice(0, ITEMS_PER_PAGE));
        }
    }, [searchQuery, selectedKeywords, events, loading]);

    const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && visibleEvents.length < filteredEvents.length) {
            setPage(prev => prev + 1);
        }
    }, [loading, visibleEvents.length, filteredEvents.length]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        });
        if (loader.current) {
            observer.observe(loader.current);
        }
        return () => observer.disconnect();
    }, [handleObserver]);

    useEffect(() => {
        const startIndex = 0;
        const endIndex = page * ITEMS_PER_PAGE;
        const eventsToShow = filteredEvents
            .filter(event => showFavorites ? favorites.includes(event.id) : true)
            .slice(startIndex, endIndex);
        setVisibleEvents(eventsToShow);
    }, [page, filteredEvents, showFavorites, favorites]);

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                localStorage.setItem('hasSeenBigPromo', 'true');
            }, 5000);
        }
    }, [loading]);

    useEffect(() => {
        const scrollToHighlightedMeet = () => {
            if (highlightedMeetId && !loading) {
                const highlightedElement = document.getElementById(`event-${highlightedMeetId}`);
                if (highlightedElement) {
                    setTimeout(() => {
                        highlightedElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    }, 300);
                }
            }
        };

        scrollToHighlightedMeet();
    }, [highlightedMeetId, loading, events]);

    if (loading) {
        return <FullScreenLoader />;
    }

    if (events.length === 0 || (showFavorites && filteredEvents.filter(event => favorites.includes(event.id)).length === 0)) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 240px)',
                    width: '100%'
                }}
            >
                <Typography sx={{ width: '240px' }} textAlign={'center'} variant="h6" color="text.primary">
                    {showFavorites ? 'Je hebt nog geen favorieten' : 'Er zijn momenteel geen meets beschikbaar'}
                </Typography>
            </Box>
        );
    }

    const renderEventCard = (event: Meet, index: number) => (
        <Box
            key={`event-${event.id}-${index}`}
            id={`event-${event.id}`}
            sx={{
                width: '100%',
                transition: 'filter 0.3s ease-in-out, opacity 0.3s ease-in-out',
                filter: highlightedMeetId && event.id !== highlightedMeetId ? 'blur(2px)' : 'none',
                opacity: highlightedMeetId && event.id !== highlightedMeetId ? 0.7 : 1,
            }}
        >
            <MeetCard
                meet={event}
                user={user}
                loading={eventCardsLoading}
                isFavorite={favorites.includes(event.id)}
                onFavorite={() => handleFavorite(event.id)}
                onHighlight={(id: string) => onHighlight(id)}
                isHighlighted={event.id === highlightedMeetId}
                onEdit={() => onEdit(event.id)}
                userLocation={userLocation}
            />
        </Box>
    );

    const renderEventsList = () => {
        const hasSeenPromo = localStorage.getItem('hasSeenBigPromo') === 'true';
        const isContestParticipant = process.env.REACT_APP_ENABLE_GIVEAWAY === 'true' && stytchUser?.created_at && new Date(stytchUser?.created_at) < new Date('2025-01-31') && user?.instagramHandle;
        const BIG_PROMO_INDEX = hasSeenPromo ? 2 : 0;
        const SMALL_PROMO_INDEX = hasActiveFilters ? 5 : 10;
        const result: React.ReactNode[] = [];

        if (user && !user.isVerified) {
            result.push(<VerifyEmailCard key="verify-email-card" email={user.email} />);
        }

        if (eventCardsLoading) {
            for (let i = 0; i < ITEMS_PER_PAGE; i++) {
                result.push(<EventCardSkeleton key={`skeleton-${i}`} />);
            }
            return result;
        }

        const promocardProps = {
            title: 'Je auto chiptunen?',
            description: isContestParticipant ? 'Bedankt voor je deelname aan de winactie! Gebruik onze kortingscode en ontvang €100,- tegoed.' : 'Gebruik onze kortingscode en ontvang €50,- tegoed.',
            imageUrl: sabrMotorsportLogo,
            promoCode: isContestParticipant ? CONTEST_DISCOUNT_CODE : DISCOUNT_CODE,
            websiteUrl: 'https://www.sabrmotorsport.nl',
            socialMedia: {
                instagram: 'https://www.instagram.com/sabrmotorsport.nl/',
                whatsapp: '+31852502229',
            }
        };

        visibleEvents.forEach((event, index) => {
            if (process.env.REACT_APP_ENABLE_PROMO === 'true') {
                if (index === BIG_PROMO_INDEX && !hasActiveFilters) {
                    result.push(<PromotionalCard key={`big-promo-${index}`} {...promocardProps} />);
                } else if (index === SMALL_PROMO_INDEX) {
                    result.push(<CompactPromotionalCard key={`compact-promo-${index}`} {...promocardProps} />);
                }
            }
            result.push(renderEventCard(event, index));
        });

        return result;
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    p: 2,
                    position: 'relative',
                    '@media (max-width: 768px)': {
                        marginBottom: isAdmin ? '53px' : '0px',
                    }
                }}
                onClick={(e) => {
                    if (e.currentTarget === e.target) {
                        onScreenClick();
                    }
                }}
            >
                {renderEventsList()}
                <div ref={loader} style={{ width: '100%', height: '20px' }} />
            </Box>
        </>
    );
};

export default EventList;
