import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Box,
    TextField,
    Button
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LanguageIcon from '@mui/icons-material/Language';

type PromotionalCardProps = {
    title: string;
    description: string;
    imageUrl: string;
    promoCode?: string;
    websiteUrl: string;
    socialMedia?: {
        whatsapp?: string;
        instagram?: string;
        website?: string;
    };
}

const PromotionalCard: React.FC<PromotionalCardProps> = ({
    title,
    description,
    imageUrl,
    promoCode,
    websiteUrl,
    socialMedia,
}) => {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleCopyCode = () => {
        if (promoCode) {
            navigator.clipboard.writeText(promoCode);
            setShowCopiedMessage(true);
            setIsAnimating(true);
            setTimeout(() => {
                setShowCopiedMessage(false);
                setIsAnimating(false);
            }, 2000);
        }
    };

    const handleVisitWebsite = () => {
        window.open(websiteUrl, '_blank', 'noopener,noreferrer');
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/${socialMedia?.whatsapp}`, '_blank');
    };

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            bgcolor: 'background.paper',
            p: 1
        }}>
            <Box sx={{ width: '100%', height: '75px', m: 1, overflow: 'hidden' }}>
                <img
                    src={imageUrl}
                    alt={title}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            </Box>

            <CardHeader
                title={
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                }
                sx={{ p: 1 }}
            />

            <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, p: 1, pt: 0 }}>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>

                {promoCode && (
                    <Box sx={{ mt: 0, width: '100%' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={promoCode}
                            label="Kortingscode"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton
                                        onClick={handleCopyCode}
                                        edge="end"
                                        sx={{
                                            'svg': { fontSize: '16pt' },
                                            '& .copied-emoji': {
                                                display: 'inline-block',
                                                animation: isAnimating ? 'popIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
                                            },
                                            '@keyframes popIn': {
                                                '0%': { transform: 'scale(0)' },
                                                '50%': { transform: 'scale(1.2)' },
                                                '100%': { transform: 'scale(1)' }
                                            }
                                        }}
                                    >
                                        {showCopiedMessage ? <span className="copied-emoji">📋</span> : <ContentCopyIcon />}
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                )}
            </CardContent>

            <CardActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 1
            }}>
                <Box>
                    {socialMedia?.whatsapp && (
                        <IconButton
                            onClick={handleWhatsAppClick}
                        >
                            <WhatsAppIcon />
                        </IconButton>
                    )}
                    {socialMedia?.instagram && (
                        <IconButton
                            href={socialMedia.instagram}
                            target="_blank"
                        >
                            <InstagramIcon />
                        </IconButton>
                    )}
                    {socialMedia?.website && (
                        <IconButton
                            href={socialMedia.website}
                            target="_blank"
                            sx={{
                                backgroundColor: '#2196F3',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#1976D2'
                                }
                            }}
                        >
                            <LanguageIcon />
                        </IconButton>
                    )}
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVisitWebsite}
                    endIcon={<OpenInNewIcon />}
                >
                    Naar de website
                </Button>
            </CardActions>
        </Card>
    );
};

export default PromotionalCard; 