import { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

type MapEventHandlerProps = {
    onMoveEnd: (center: { lat: number; lng: number }) => void;
};

const MapEventHandler: React.FC<MapEventHandlerProps> = ({ onMoveEnd }) => {
    const map = useMap();
    const isInitialLoad = useRef(true);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const lastCenter = useRef<{ lat: number; lng: number } | null>(null);
    const isDragging = useRef(false);
    const isTouching = useRef(false);

    const roundCoordinate = (num: number): number => {
        return Number(num.toFixed(4));
    };

    const hasSignificantChange = (prev: { lat: number; lng: number }, curr: { lat: number; lng: number }): boolean => {
        const roundedPrevLat = roundCoordinate(prev.lat);
        const roundedPrevLng = roundCoordinate(prev.lng);
        const roundedCurrLat = roundCoordinate(curr.lat);
        const roundedCurrLng = roundCoordinate(curr.lng);

        return roundedPrevLat !== roundedCurrLat || roundedPrevLng !== roundedCurrLng;
    };

    useEffect(() => {
        const handleMoveEnd = () => {
            if (isInitialLoad.current) {
                isInitialLoad.current = false;
                const center = map.getCenter();
                lastCenter.current = {
                    lat: roundCoordinate(center.lat),
                    lng: roundCoordinate(center.lng)
                };
                return;
            }

            // Only process move end if it was triggered by user interaction
            if (isDragging.current || isTouching.current) {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }

                const center = map.getCenter();
                const currentCenter = {
                    lat: center.lat,
                    lng: center.lng
                };

                if (lastCenter.current && hasSignificantChange(lastCenter.current, currentCenter)) {
                    timeoutRef.current = setTimeout(() => {
                        onMoveEnd(currentCenter);
                        lastCenter.current = currentCenter;
                    }, 300);
                }

                isDragging.current = false;
                isTouching.current = false;
            }
        };

        const handleDragStart = () => {
            isDragging.current = true;
        };

        const handleTouchStart = () => {
            isTouching.current = true;
        };

        map.on('moveend', handleMoveEnd);
        map.on('dragstart', handleDragStart);
        map.on('touchstart', handleTouchStart);

        return () => {
            map.off('moveend', handleMoveEnd);
            map.off('dragstart', handleDragStart);
            map.off('touchstart', handleTouchStart);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [map, onMoveEnd]);

    return null;
};

export default MapEventHandler;