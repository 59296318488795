import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import FilterBar from '../components/filter-bar/FilterBar';
import EventList from '../components/event-list/EventList';
import TopBar from '../components/top-bar/TopBar';
import { Meet } from '../types/Meet';
import SortMenu from '../components/sort-menu/SortMenu';
import EventMap from '../components/event-map/EventMap';
import Footer from '../components/footer/Footer';
import CookieConsent from '../components/cookie-consent/CookieConsent';
import EventListSchema from '../components/schema/EventListSchema';
import { useStytchUser } from '@stytch/react';
import CreateMeetButton from '../components/create-meet-button/CreateMeetButton';
import { useMeets } from '../hooks/useMeets';
import { useUser, useFavoriteMeets } from '../hooks/useUser';


function degreesToRadians(degrees: number) {
  return degrees * Math.PI / 180;
}

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}

const getNextOccurrence = (date: string, recurringDay: number): Date => {
  const eventDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  while (eventDate < today || eventDate.getDay() !== recurringDay) {
    eventDate.setDate(eventDate.getDate() + 1);
  }

  return eventDate;
};

const Home: React.FC = () => {
  const { meets, isLoading: meetsLoading, deleteMeet } = useMeets();
  const { user, isLoading: userLoading } = useUser();
  const { favorites, isLoading: favoritesLoading, addFavorite, removeFavorite } = useFavoriteMeets();
  const [searchQuery, setSearchQuery] = useState('');
  const [isSorting, setIsSorting] = useState(false);
  const isLoading = useMemo(() => meetsLoading, [meetsLoading]);
  const [isEventCardsLoading, setIsEventCardsLoading] = useState(true);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [showHostedMeets, setShowHostedMeets] = useState(false);
  const [filteredMeets, setFilteredMeets] = useState<Meet[]>([]);
  const [highlightedMeetId, setHighlightedMeetId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);
  const [userLocation, setUserLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [sortOption, setSortOption] = useState<{ value: string; inverted: boolean }>({ value: 'date', inverted: false });
  const [meetNotFound, setMeetNotFound] = useState<boolean>(false);
  const [view, setView] = useState<'list' | 'map'>(() => {
    const params = new URLSearchParams(window.location.search);
    const urlView = params.get('view');
    if (urlView === 'map') return 'map';
    return 'list';
  });
  const { user: stytchUser } = useStytchUser();

  useEffect(() => {
    // Get keywords from URL params on initial load
    const params = new URLSearchParams(window.location.search);
    const urlKeywords = params.getAll('keywords');
    const urlIsHostedMeets = params.get('user_meets') === 'true';
    if (urlKeywords.length > 0) {
      setSelectedKeywords(urlKeywords);
      filterMeets('', urlKeywords);
    }
    setShowHostedMeets(urlIsHostedMeets);
  }, []);

  const handleViewChange = (newView: 'list' | 'map') => {
    setView(newView);
    // Update URL with view parameter while preserving keywords
    const searchParams = new URLSearchParams(window.location.search);
    if (newView === 'map') {
      searchParams.set('view', 'map');
    } else {
      searchParams.delete('view');
    }

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  useEffect(() => {
    if (meets) {
      const sortedEvents = [...meets].sort((a, b) => {
        const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay) : new Date(a.date);
        const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay) : new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      });

      const params = new URLSearchParams(window.location.search);
      const urlKeywords = params.getAll('keywords');

      const filteredMeets = sortedEvents.filter(event =>
        urlKeywords.every(kw => event.keywords.includes(kw))
      );

      setFilteredMeets(filteredMeets);
    }
  }, [meets]);


  useEffect(() => {
    if (!meetsLoading && !isSorting && !userLoading) {
      setTimeout(() => {
        setIsEventCardsLoading(false);
      }, 100);
    } else {
      setIsEventCardsLoading(true);
    }
  }, [meetsLoading, isSorting, userLoading]);

  useEffect(() => {
    if (sortOption.value === 'distance') {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.warn('Geolocation is not available in your browser.');
        // setSortOption({ value: 'date', inverted: false });
      }
    }
  }, [sortOption]);

  useEffect(() => filterMeets(), [userLocation]);

  const handleFavorite = async (eventId: string) => {
    if (favorites.includes(eventId)) {
      await removeFavorite(eventId);
    } else {
      await addFavorite(eventId);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterMeets(query, selectedKeywords, sortOption);
  };

  const handleFilterChange = (newSelectedKeywords: string[]) => {
    setSelectedKeywords(newSelectedKeywords);
    filterMeets(searchQuery, newSelectedKeywords, sortOption);

    // Update URL params
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('keywords');
    newSelectedKeywords.forEach(keyword => {
      searchParams.append('keywords', keyword);
    });

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
  };

  const handleHostedMeetsChange = (showHostedMeets: boolean) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (showHostedMeets) {
      searchParams.set('user_meets', 'true');
    } else {
      searchParams.delete('user_meets');
    }
    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState(null, '', newUrl);
    setShowHostedMeets(showHostedMeets);
    filterMeets(searchQuery, selectedKeywords, sortOption, showHostedMeets);
  };

  const handleFavoriteFilter = (showFavorites: boolean) => {
    setShowFavorites(showFavorites);
  };

  const filterMeets = (query: string = searchQuery, keywords: string[] = selectedKeywords, sortOpt = sortOption, hostedMeets = showHostedMeets) => {
    const filtered = meets.filter(meet => {
      const matchesSearch = query === '' ||
        meet.title.toLowerCase().includes(query.toLowerCase()) ||
        meet.location.toLowerCase().includes(query.toLowerCase());

      const matchesKeywords = keywords.length === 0 ||
        keywords.every(kw => meet.keywords.includes(kw));

      const matchesHostedMeets = !hostedMeets ||
        user?.hosted_meets?.some(hostedMeet => hostedMeet.id === meet.id);

      const matchesFavorites = !showFavorites ||
        favorites.includes(meet.id);
      return matchesSearch && matchesKeywords && matchesHostedMeets && matchesFavorites;
    });

    filtered.sort((a, b) => {
      const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay) : new Date(a.date);
      const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay) : new Date(b.date);

      switch (sortOpt.value) {
        case 'date':
          return sortOpt.inverted
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        case 'distance':
          if (userLocation && a.latitude && a.longitude && b.latitude && b.longitude) {
            const distanceA = calculateDistance(userLocation.lat, userLocation.lng, a.latitude, a.longitude);
            const distanceB = calculateDistance(userLocation.lat, userLocation.lng, b.latitude, b.longitude);
            return sortOpt.inverted ? distanceB - distanceA : distanceA - distanceB;
          }
          return 0;
        case 'title':
          return sortOpt.inverted
            ? b.title.localeCompare(a.title)
            : a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });

    setFilteredMeets(filtered);
  };

  const handleScreenClick = () => {
    if (highlightedMeetId) {
      setHighlightedMeetId(null);
    }
  };

  const handleSortChange = async (option: { value: string; inverted: boolean }) => {
    setIsSorting(true);
    setSortOption(option);

    if (option.value === 'distance' && !userLocation) {
      // Get user location if sorting by distance
      try {
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
              resolve(position);
            },
            (error) => reject(error)
          );
        });
      } catch (error) {
        console.error('Error getting user location:', error);
      }
    }

    filterMeets(searchQuery, selectedKeywords, option);
    setIsSorting(false);
  };

  const handleHighlight = (id: string) => {
    setHighlightedMeetId(id);
  };

  return (
    <>
      <EventListSchema events={filteredMeets} />
      <Box sx={{
        '@media (min-width: 768px)': {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'center',
          width: '100%',
          minHeight: '100vh'
        }
      }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: 0,
            '@media (min-width: 768px)': {
              width: '600px',
            },
            filter: meetNotFound ? 'blur(5px)' : 'none',
            pointerEvents: meetNotFound ? 'none' : 'auto'
          })}
        >
          <Box sx={{ flex: 1 }}>
            <TopBar onSearch={meets.length > 0 ? handleSearch : undefined} onFavoriteFilter={handleFavoriteFilter} view={view} onViewChange={handleViewChange} />
            {meets.length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FilterBar
                  events={filteredMeets}
                  onFilterChange={handleFilterChange}
                  onHostedMeetsChange={handleHostedMeetsChange}
                  showFavorites={showFavorites}
                  favorites={favorites}
                  selectedKeywords={selectedKeywords}
                  user={user || null}
                  showHostedMeets={showHostedMeets}
                />
                {view === 'list' && <SortMenu onSortChange={handleSortChange} isLoading={isSorting} />}
              </Box>
            )}
            {view === 'list' ? (
              <EventList
                user={user || null}
                userLocation={userLocation}
                events={filteredMeets}
                searchQuery={searchQuery}
                loading={isLoading}
                eventCardsLoading={isEventCardsLoading}
                selectedKeywords={selectedKeywords}
                highlightedMeetId={highlightedMeetId}
                showFavorites={showFavorites}
                onScreenClick={handleScreenClick}
                onHighlight={handleHighlight}
                onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
                favorites={favorites}
                handleFavorite={handleFavorite}
              />
            ) : (
              <EventMap
                events={filteredMeets}
                user={user || null}
                isLoading={isLoading}
                userLocation={userLocation}
                showFavorites={showFavorites}
                onHighlight={handleHighlight}
                onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
                favorites={favorites}
                handleFavorite={handleFavorite}
              />
            )}
          </Box>
          <CookieConsent />
          <Footer />
        </Box>
        {meetNotFound && (
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              backgroundColor: 'background.paper',
              padding: 3,
              borderRadius: 2,
              boxShadow: 24,
              textAlign: 'center',
              maxWidth: '90%',
              width: '400px'
            }}
          >
            <Typography variant="h5" gutterBottom>
              Meet niet gevonden
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              De meet waar je naar zoekt bestaat niet of is verwijderd.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/');
                setMeetNotFound(false);
              }}
            >
              Terug naar overzicht
            </Button>
          </Box>
        )}
        {(process.env.REACT_APP_ENABLE_MEET_CREATION === 'true' || user?.isAdmin) && <CreateMeetButton isHost={user?.role === 'host' || false} />}
      </Box>
    </>
  );
};

export default Home;
