import { SWRConfiguration } from 'swr';
import { useStytch } from '@stytch/react';

export const getAuthHeader = (sessionToken?: string) => ({
    'Authorization': `Bearer ${sessionToken}`
});

export const defaultConfig: SWRConfiguration = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 0,
    shouldRetryOnError: true
};

export const fetcher = async ([url, sessionToken]: [string, string | undefined]) => {
    const response = await fetch(url, {
        headers: getAuthHeader(sessionToken)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

export const mutationFetcher = async (url: string, { arg }: { arg: any }, sessionToken?: string) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...getAuthHeader(sessionToken),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(arg)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}; 