import React from 'react';
import { JsonLd } from 'react-schemaorg';
import { ItemList, WithContext } from 'schema-dts';
import { Meet } from '../../types/Meet';

interface EventListSchemaProps {
    events: Meet[];
}

const EventListSchema: React.FC<EventListSchemaProps> = ({ events }) => {
    const schema: WithContext<ItemList> = {
        '@type': 'ItemList',
        '@context': 'https://schema.org',
        itemListElement: events.map((event, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@type': 'Event',
                name: event.customTitle || event.title,
                description: event.seoDescription || event.description,
                startDate: event.recurringDay ?
                    (() => {
                        const today = new Date();
                        const dayOfWeek = event.recurringDay;
                        const diff = (dayOfWeek + 7 - today.getDay()) % 7;
                        const nextDate = new Date(today);
                        nextDate.setDate(today.getDate() + diff);
                        return nextDate.toISOString();
                    })() :
                    event.date,
                location: {
                    '@type': 'Place',
                    name: event.location,
                    ...(event.latitude && event.longitude && {
                        geo: {
                            '@type': 'GeoCoordinates',
                            latitude: event.latitude,
                            longitude: event.longitude
                        }
                    })
                },
                ...(event.keywords?.length && { keywords: event.keywords.join(', ') }),
                ...(event.recurringDay !== null && {
                    eventSchedule: {
                        '@type': 'Schedule',
                        byDay: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][event.recurringDay],
                        repeatFrequency: 'P1W'
                    }
                })
            }
        }))
    };

    return <JsonLd<ItemList> item={schema} />;
};

export default EventListSchema;