import React, { useState } from 'react';
import {
    Card,
    CardContent,
    IconButton,
    Typography,
    Box,
    Button
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { WhatsApp } from '@mui/icons-material';

type CompactPromotionalCardProps = {
    title: string;
    description: string;
    imageUrl: string;
    promoCode?: string;
    websiteUrl: string;
    socialMedia?: {
        instagram?: string;
        whatsapp?: string;
    };
}

const CompactPromotionalCard: React.FC<CompactPromotionalCardProps> = ({
    title,
    description,
    imageUrl,
    promoCode,
    websiteUrl,
    socialMedia,
}) => {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleCopyCode = () => {
        if (promoCode) {
            navigator.clipboard.writeText(promoCode);
            setShowCopiedMessage(true);
            setIsAnimating(true);
            setTimeout(() => {
                setShowCopiedMessage(false);
                setIsAnimating(false);
            }, 2000);
        }
    };

    const handleVisitWebsite = () => {
        window.open(websiteUrl, '_blank', 'noopener,noreferrer');
    };

    const handleWhatsApp = () => {
        window.open(`https://wa.me/${socialMedia?.whatsapp}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: '200px',
            bgcolor: 'background.paper',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Box sx={{ width: '150px', height: '100%', p: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                        src={imageUrl}
                        alt={title}
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
                <CardContent sx={{ p: 1 }}>
                    <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', fontSize: '12pt', mb: 0.5 }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem' }}>
                        {description}
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', px: 1, pb: 1 }}>
                    <Box>
                        {socialMedia?.instagram && (
                            <IconButton
                                href={socialMedia.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                            >
                                <InstagramIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                        )}
                        {socialMedia?.whatsapp && (
                            <IconButton
                                onClick={handleWhatsApp}
                                size="small"
                            >
                                <WhatsApp sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                        )}
                    </Box>
                    {promoCode && (
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                            <Typography variant="body2" sx={{ mr: 1 }}>
                                Code: <strong>{promoCode}</strong>
                            </Typography>
                            <IconButton
                                onClick={handleCopyCode}
                                size="small"
                                sx={{
                                    p: 0.5,
                                    '& .copied-emoji': {
                                        display: 'inline-block',
                                        animation: isAnimating ? 'popIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
                                    },
                                    '@keyframes popIn': {
                                        '0%': { transform: 'scale(0)' },
                                        '50%': { transform: 'scale(1.2)' },
                                        '100%': { transform: 'scale(1)' }
                                    }
                                }}
                            >
                                {showCopiedMessage ? <span className="copied-emoji">📋</span> : <ContentCopyIcon sx={{ fontSize: '1rem' }} />}
                            </IconButton>
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleVisitWebsite}
                        endIcon={<OpenInNewIcon sx={{ fontSize: '1rem' }} />}
                        sx={{ ml: 'auto' }}
                    >
                        Website
                    </Button>
                </Box>
            </Box>
        </Card >
    );
};

export default CompactPromotionalCard;
